<template>
  <div class="p-2">
    <div class="mb-2">
      <select
        class="form-control form-control-sm shadow-none col-md-1 d-inline"
        v-model="year"
        @change="change_year"
      >
        <option v-for="(item, idx) in years" :key="idx + 'u'" :value="item">{{
          item
        }}</option>
      </select>
      <div v-if="all_data" class="d-inline">
        всего ремонтов: {{ all_data.all_repairs }} шт. всего заявок:
        {{ all_data.all_orders }} шт.
      </div>
    </div>
    <div v-if="repairs_data" class="d-flex flex-row justify-content-start">
      <table
        class="table table-sm table-responsive"
        v-for="(item, idx) in repairs_data"
        :key="idx + 'q'"
      >
        <tr>
          <th></th>
          <th>{{ item.name }}</th>
          <th>
            {{ `${item.summ} шт. | ${item.percent}%` }}
            <div class="text-smal">ремонты</div>
          </th>
          <th>
            {{ `${item.guaranty_orders_count} шт. | ${item.percent_orders}%` }}
            <div class="text-smal">заявки</div>
          </th>
        </tr>
        <tr v-for="(item2, idx) in item.data" :key="idx + 'w'">
          <td>{{ idx + 1 }}</td>
          <td>{{ item2.user }}</td>
          <td class="text-center">{{ item2.repairs_count }}</td>
          <td class="text-center">{{ item2.guaranty_orders_count }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "../../../api";
export default {
  name: "MenedgerStats",
  data: () => ({
    year: new Date().getFullYear(),
    years: [],
    repairs_data: null,
    all_data: null
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "/admin/menedger_stats/get_data.php",
        params: {
          year: this.year
        }
      }).then(response => {
        this.years = response.data.years;
        this.repairs_data = response.data.repairs_data;
        this.all_data = response.data.all;
      });
    },
    change_year() {
      this.get_data();
    }
  }
};
</script>
<style scoped>
td,
th {
  font-size: 0.7em;
  vertical-align: middle;
}
.text-smal {
  font-size: 0.8em;
  text-align: center;
}
</style>
